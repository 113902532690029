@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './styles/mixins/main.scss';
@import './styles/variables.scss';
@import './styles/shared/defaults.scss';
@import './styles//dependency.scss';
@import './../node_modules/ngx-sharebuttons/themes/circles.scss';

* {
  box-sizing: border-box;
}

html,
body {
  @include clear();
  @include full-height();

  background-color: black;
}

body {
  font-family: Roboto;
  font-size: 16px;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 1rem;
}
