@import './breakpoints.scss';

@mixin clear {
  margin: 0;
  padding: 0;
}

@mixin image {
  height: 100%;
  width: 100%;
}

@mixin image-rounded {
  @include image();

  border-radius: 50%;
  object-fit: contain;
}

@mixin full-height {
  min-height: 100%;
  height: fit-content;
}

@mixin clear-button {
  background: none;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

@mixin fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 50%!important;

}
.drag-scroll-content{
  overflow: auto!important;
}
.cdk-overlay-pane{
  max-width: 94vw!important;
}